@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";

html, body { height: 100%; }
body { margin: 0; font-family: 'monserrat' !important; }

@font-face {
  font-family: 'monserrat';
  src: url('assets/fonts/Montserrat/Montserrat-Regular.ttf');
}

@font-face {
    font-family: 'monserrat';
    src: url('assets/fonts/Montserrat/Montserrat-Bold.ttf');
    font-weight: bold;
  }

@font-face {
    font-family: 'gmx';
    src: url('assets/fonts/GMX.otf');
}

.bold{
    font-weight: bold;
}

/* //override bootstrap */
.form-control{
    border-radius: 0 !important;
    border-color: #CED4DA !important;
}

.hide-input {
    display: none !important;
}

.invalid-radio{
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}

.primary-color-text {
    color: #495057;
}

select.form-control{
    border: 0;
    outline: 1px solid #CED4DA !important;
    -webkit-border-radius: 0px;
}



.btn{
    border-radius: 5px !important;
    font-weight: bold;
}

.btn-primary{
    color: #fff;
    background-color: #495057 !important;
    border-color: #495057 !important;
}

.btn-primary:active{
    color: #fff;
    background-color: #ec491c !important;
    border-color: #ec491c !important;
}

.btn-secondary{
    color: #fff;
    background-color: #6C757D !important;
    border-color: #6C757D !important;
}

.btn-secondary:active{
    color: #fff;
    background-color: #6C757D !important;
    border-color: #6C757D !important;
}

.btn-info{
    color: #fff;
    background-color: #684af5 !important;
    border-color: #684af5 !important;
}

.btn-info:active{
    color: #fff;
    background-color: #593ac8 !important;
    border-color: #593ac8 !important;
}


/* //icon addon */

.center-block {
    float: none;
    margin-left: auto;
    margin-right: auto;
}

.input-group .icon-addon .form-control {
    border-radius: 0;
}

.icon-addon {
    position: relative;
    color: #555;
    display: block;
}

.icon-addon:after,
.icon-addon:before {
    display: table;
    content: " ";
}

.icon-addon:after {
    clear: both;
}

.icon-addon.addon-md img,
.icon-addon img, 
.icon-addon.addon-md .fa,
.icon-addon .fa {
    position: absolute;
    z-index: 2;
    left: 20px;
    font-size: 14px;
    width: 1.2em;
    margin-left: -2.5px;
    text-align: center;
    padding: 10px 0;
    top: 1px
}

.icon-addon.addon-lg .form-control {
    line-height: 1.33;
    height: 46px;
    font-size: 18px;
    padding: 10px 16px 10px 40px;
}

.icon-addon.addon-sm .form-control {
    height: 30px;
    padding: 5px 10px 5px 28px;
    font-size: 12px;
    line-height: 1.5;
}

.icon-addon.addon-lg .fa,
.icon-addon.addon-lg img {
    font-size: 18px;
    margin-left: 0;
    left: 11px;
    width: auto;
}

.icon-addon.addon-md .form-control,
.icon-addon .form-control {
    padding-left: 40px;
    float: left;
    font-weight: normal;
}

.icon-addon.addon-sm .fa,
.icon-addon.addon-sm img {
    margin-left: 0;
    font-size: 12px;
    left: 5px;
    top: -1px
}

.height-100{
    height: 100%;
}

.bg-body{
    background-color: #dcdfe2 !important;
}

.border-line-bottom {
    border-bottom: 2px solid #495057;
}

.bg-strapp{
    background-color:  #495057;
}

.text-strapp{
    color: #495057;
}


.nav-shadow{
    -webkit-box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.3);
    box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.3);
}

/* //El Siglo Styles */

h1,h2,h3,h4,h5,h6{
    font-weight: bold !important;
    line-height: 3;
    text-transform: uppercase;
}

.bg-primary-color-1{
    background-color: #1e2c58;
}

.text-primary-color-1{
    color: #1e2c58;
}

.bg-primary-color-2{
    background-color: #fc6420;
}

.text-primary-color-2{
    color: #fc6420;
}

.bg-primary-color-3{
    background-color: #0058a5;
}

.text-primary-color-3{
    color: #495057;
}

.bg-primary-color-4{
    background-color: #1c7dc9;
}

.text-primary-color-4{
    color: #1c7dc9;
}

.bg-primary-color-5{
    background-color: #684af5;
}

.text-primary-color-5{
    color: #684af5;
}


.bg-secundary-gray-1{
    background-color: #383838;
}

.text-secundary-gray-1{
    color: #383838;
}

.bg-secundary-gray-2{
    background-color: #929192;
}

.text-secundary-gray-2{
    color: #929192;
}

.bg-secundary-gray-3{
    background-color:#9cb7d7;
}

.text-secundary-gray-3{
    color:#9cb7d7;
}

.bg-secundary-back-1{
    background-color: #f0f3f8;
}

.text-secundary-back-1{
    color: #f0f3f8;
}

.bg-secundary-back-2{
    background-color: #0e2849;
}

.text-secundary-back-2{
    color: #0e2849;
}

.bg-secundary-back-3{
    background-color: #84cceb;
}

.text-secundary-back-3{
    color: #84cceb;
}

.is-invalid::placeholder{
    color: #dc3545;
}



/* //nav bar */

.breadcrumb{
    border-radius: 0 !important;
    background-color: #E9ECEF !important;
}

.breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6C757D !important;
    content: "/";
}


.breadcrumb-item + .breadcrumb-item.active::before {
    font-weight: normal !important;
}

.breadcrumb-item{
    padding-top: .5em;
}

.breadcrumb-item.active{
    color: #6C757D !important;
    font-weight: bold;
}


.breadcrum-image{
    height: 2em;
    margin-right: .5em;
}

/* //profile image */

.circular-landscape {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 50%;
}

.circular-landscape img {
    width: auto;
    height: 100%;
    margin-left: -50px;
}

/* //list */

th{
    background-color: #6C757D;
    color: white;
    font-weight: normal;
    border:none !important;
}

.table th:first-child{
    border-radius: 5px 0px 0px 0px;
}

.table th:last-child{
    border-radius: 0px 5px 0px 0px;
    background-color: #343A40;
    color: white;
    font-weight: normal;
}

.table td{
    background-color: #EEE;
    border-top: 1px solid #FFF !important;
}


.input-search{
    border-radius: 5px !important;
    border: none !important;
    background-color: #EEE !important;
}

.input-search-report{
    border-color:  #0058a5 !important;
}

.input-search-border{
    border-radius: 2em !important;
}

.input-search::placeholder{
    color: #0058a5;
}


.icon-list{
    width: 1.4em;
}


.bg-form{
    background-color: white;
    padding: 0 5rem 2rem 5rem;
}

@media (max-width: 667px) {
    .bg-form{
        background-color: white;
        padding: 0 2rem 2rem 2rem;
    }
}

.bg-white{
    background-color: white;
}


/* //home icons */

.btn-home{
    text-align: center;
    padding:0px 0px 0px 0px !important;
    margin: 10px;
    background-color: white;
}

.btn-home-icon{
    padding: 30px 0 30px 0;
}


.btn-home-label{
    padding: 10px 0 10px 0;
    color: white;
    font-weight: bold;
    height: 45%;
    font-size: 1.1em;
}

.btn-admin-label{
    padding: 10px 0 10px 0;
    color: white;
    font-weight: bold;
    height: 45%;
    font-size: 1.1em;
}

.btn-area-label{
    background-color: white;
    padding: 10px 0 10px 0;
    font-weight: bold;
    color:#495057;
}

.icon-home{
    height: 3em;
}

/* //tabs */

ngb-tabset .nav{
    width: 100%;
}

ngb-tabset .nav-tabs .nav-link{
    background-color:#1c7dc9; 
    border:none;
    text-decoration: none;
    border-radius: 0;
    color: white;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 1em;
}

ngb-tabset .nav-tabs .nav-link.active{
    background-color:#0058a5; 
    border:none;
    text-decoration: underline;
    border-radius: 0;
    color: white;
}

ngb-tabset .tab-content{
    padding: 1em;
}

.image-popup{
    max-width: 600px;
}


/* //drag and drop */

.example-container {
    width: 400px;
    max-width: 100%;
    margin: 0 25px 25px 0;
    display: inline-block;
    vertical-align: top;
  }
  
  .example-list {
    border: solid 1px #ccc;
    min-height: 60px;
    background: white;
    border-radius: 4px;
    overflow: hidden;
    display: block;
    height: 100%;
  }
  
  .example-box {
    padding: 20px 10px;
    border-bottom: solid 1px #ccc;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    background: white;
    font-size: 14px;
  }
  
  .cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
                0 8px 10px 1px rgba(0, 0, 0, 0.14),
                0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
  
  .cdk-drag-placeholder {
    opacity: 0;
  }
  
  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
  
  .example-box:last-child {
    border: none;
  }
  
  .example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }

/* // Carrousel */

.carousel-control-prev-icon{
    background-image: url('/assets/images/angle-left-solid.svg');
    width: 50px; 
    height: 50px;
 }
 .carousel-control-next-icon{
   background-image: url('/assets/images/angle-right-solid.svg');
   width: 50px; 
   height: 50px;
 }
 
 .carousel-indicators li {
    border-radius: 12px;
    width: 12px;
    height: 12px;
    background-color: #404040;
}

.carousel-indicators .active {
    background-color: black;
    max-width: 12px;
    margin: 0 3px;
    height: 12px;
}


/* //priority labels */

.notification-green{
    color: white;
    background: green;
}

.notification-yellow{
    color: black;
    background: yellow;
}

.notification-red{
    color: white;
    background: red;
}

.notification-active{
    color:#007bff
}

/* // task */

.task-box {
    padding: 20px 10px;
    border-bottom: solid 1px #ccc;
    color: rgba(0, 0, 0, 0.87);
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    background: white;
    font-size: 14px;
}

.task-hr {
    color: rgba(0, 0, 0, 0.87);
    border-top: 1px solid rgba(0, 0, 0, 0.87);
}


.task-label {
    justify-content: left;
}

/* //carrousel-img */

.carrousel-img{
    max-height: 400px;
}


h1, h2, h3, h4, h5, h6 {
    line-height:3 !important;
}


html,
body {
  height: 100%;
}

#page-content {
  flex: 1 0 auto;
}

#sticky-footer {
  flex-shrink: none;
  z-index: 100;
  background-color: #495057;
}

.footer-link {
    color: #FFF;
    text-decoration: underline;
}

.footer-link:hover {
    color: #CED4DA;
}

.bg-admin-icon {
    background-color: #DEE2E6;
    color: #495057;
}

.btn-home{
    text-align: center;
    padding:0px 0px 0px 0px !important;
    margin: 10px;
    background-color: white;
}

.btn-home-icon{
    padding: 30px 0 30px 0;
}


.btn-home-label{
    padding: 10px 0 10px 0;
    color: white;
    background-color: cornflowerblue;
    font-weight: bold;
    height: 45%;
    font-size: 1.1em;
}

.btn-area-label{
    background-color: white;
    padding: 10px 0 10px 0;
    font-weight: bold;
    color:#495057;
}

.icon-home{
    height: 3em;
}

.navbar-primary {
    display: none;
}

.delete-img {
    color: red;
    position: absolute;
    right: .5em;
    top: .5em;
    border: none;
    background: none;
}

image-cropper {
    max-height: 400px !important;
    max-width: 400px !important;
}

.custom-select{
    border: 1px solid #CED4DA !important;
}

.page-item.active .page-link {
    background-color: #495057 !important;
    border-color: #495057 !important;
}

.page-item .page-link {
    color: #000;
    border-color: #CED4DA !important;
}

.favicon-nav{
    color:#495057;
    padding-right: 10px;
}

.preregister-border{
    border: 1px solid #CED4DA;
}

/* clean zoom style */

.navbar:before, .navbar:after {
    /*content: none !important;*/
}

body{
    overflow: scroll !important;
}

#zmmtg-root footer button {
    background-image: linear-gradient(to bottom, #26282a, #1c1e20) !important;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5)!important;
}

.open > .dropdown-menu {
    display: block !important;
}

.chat-header__dropdown-menu {
    min-width: 80px !important;
}

.chat-container__chat-control {
    display: flex;
}

.btn-group > .btn:first-child {
    margin-left: 0 !important;
}

.btn-group > .btn, .btn-group-vertical > .btn {
    position: relative !important;
    float: left !important;
}


.zm-btn--lg{
    width: 300px !important;
}

.chat-header__pop-out-icon, .chat-header__close-icon {
    background: none !important;
}

.raffle-payment {
    background-color: rgb(229, 226, 226);
    padding: 1em;
    border-radius: 1em;
    margin-bottom: 1em;
}